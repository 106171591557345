
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'Clients',
  data () {
    return {
      addMode: false,
      rules: {
        required: value => !!value || 'Value Required',
      },
      tuyaAccount: '',
      clientName: '',
      deviceId: '',
      isPublic: true,
      valid: true,
      loading: false,
    }
  },
  computed: {
    headersClients() {
      if (store.state.auth.userGroup == 'owner') {
        return [
          { text: this.$t('clients.table_col_delete_client'), value: 'deleteClient', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_goto_devices'), value: 'gotoDevices', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_goto_users'), value: 'gotoUsers', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_client_name'), value: 'clientName', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_client_id'), value: 'clientId', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_client_secret'), value: 'clientSecret', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_tuya_account'), value: 'tuyaAccount', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_tuya_uid'), value: 'table_col_tuya_uid', align: 'left', sortable: false }
        ]
      } else {
        return [
          { text: this.$t('clients.table_col_goto_devices'), value: 'gotoDevices', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_goto_users'), value: 'gotoUsers', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_client_name'), value: 'clientName', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_client_id'), value: 'clientId', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_client_secret'), value: 'clientSecret', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_tuya_account'), value: 'tuyaAccount', align: 'left', sortable: false },
          { text: this.$t('clients.table_col_tuya_uid'), value: 'table_col_tuya_uid', align: 'left', sortable: false }
        ]
      }
    },
    ...mapState({
      clients: (state: any) => state.clients.clients,
      userGroup: (state: any) => state.auth.userGroup
    }),
  },
  methods: {
    async deleteExisting (item) {
      this.loading = true

      await this.deleteClient({clientId: item.clientId})

      await this.listClients()

      this.loading = false

    },
    async saveNew () {
      this.loading = true
      await this.addClient({deviceId: this.deviceId, clientName: this.clientName, tuyaAccount: this.tuyaAccount, isPublic: this.isPublic})

      await this.listClients()

      this.addMode = !this.addMode
      this.tuyaAccount = ''
      this.clientName = ''
      this.deviceId = ''
      this.isPublic = true
      this.loading = false

    },
    gotoDevices (item) {
      this.loading = true
      console.log(`gotoDevices item: ${JSON.stringify(item)}`)

      try {
        this.pickClient(item)

        if (this.$router.currentRoute.name === 'Devices') {
          this.$router.go(0)
        } else {          
          this.$router.push({name: 'Devices'})
        }
        
      } catch(err) {
        console.log(err.message)
        this.$router.push({name: 'Logout'})
      }

    },
    gotoUsers (item) {
      this.loading = true
      console.log(`gotoUsers item: ${JSON.stringify(item)}`)

      try {

        this.pickClient(item)

        if (this.$router.currentRoute.name === 'Users') {
          this.$router.go(0)
        } else {          
          this.$router.push({name: 'Users'})
        }

      } catch(err) {
        console.log(err.message)
        this.$router.push({name: 'Logout'})
      }

    },
    ...mapActions({
      pickClient: 'auth/pickClient',
      addClient: 'clients/addClient',
      listClients: 'clients/listClients',
      deleteClient: 'clients/deleteClient',
    }),
  },
  async beforeRouteEnter (to, from, next) {

    console.log('store.auth', store.state.auth)

    let error

    if (store.state.auth.userGroup == 'owner') {
      error = await store.dispatch('clients/listClients').catch(err => {
        return err
      })
    } else if (store.state.auth.userGroup == 'tenant') {
      error = await store.dispatch('clients/getClient', {clientId: store.state.auth.client.clientId}).catch(err => {
        return err
      })
    }

    if (error) {
      window.alert(error)
      router.push({name: 'Logout'})
    } else {
      next()
    }
  },
})
